import * as React from "react"
import { Modal, Input, Checkbox, FormControlLabel } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { StaticImage } from "gatsby-plugin-image";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SCButton from "../../buttons/SCButton";


const CvvModal = ({location, show, onClose }) => {

    const [ispwa,setIspwa] = React.useState(null)

    React.useEffect(() => {
        location != '' ? setIspwa(true) : setIspwa(false)
    }, [])

    return (
        <Modal open={show} onClose={onClose}>
            <div className={!ispwa ? 'h-screen flex items-center' : 'w-full h-screen flex justify-center items-center '} style={!ispwa ? null : { backgroundColor: '#C4C4C440' }}>
                <div className={!ispwa ? 'relative bg-white z-20 rounded-lg px-5 pt-8 pb-4 md:overflow-y-auto mx-auto' : 'h-auto bg-white rounded-xl mx-3 px-5 py-4 '}
                    style={!ispwa ? { maxHeight: "85%", maxWidth: 450 } : null}>
                    <header>
                        {false ?
                            <div className="flex justify-end">
                                <CloseIcon onClick={onClose}></CloseIcon>
                            </div> : null}
                    </header>
                    <main>
                        <div className="flex items-center justify-center h-12 mb-2">
                            <div className="flex justify-center items-center w-12 h-12 mr-2 rounded-full shadow border text-sc-primary">
                                <VpnKeyIcon></VpnKeyIcon>
                            </div>
                            <h3 className="text-sc-black mt-5" style={{ fontSize: 20 }}>Ayuda CVV</h3>
                        </div>
                        <hr className="h-1" style={{ backgroundColor: '#6B6B6B' }}></hr>
                        <div className="w-full mb-8">
                            <p>El código de seguridad lo podrán encontrar en la parte posterior de tu tarjeta y es de 3 digitos.</p>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center">
                            <StaticImage width={335} height={144} src="../../../images/cvv.png" alt="CVV help" objectFit="contain"></StaticImage>
                            <div className="my-8 shadow-md rounded-full" style={{ width: 250 }}>
                                <SCButton onClick={onClose} ><b>Continuar</b></SCButton>
                            </div>
                        </div>
                    </main>
                    <footer></footer>
                </div>
            </div>
        </Modal>
    )
}

export default CvvModal
