import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { suscribeApplePay, paymentIntentRequest } from '../../api/content';
import firebase from 'firebase';
import config, { firebaseInit } from "../../config"

const baseUri = config.serverUri

const CheckoutFormApplePay = ({ suscriptionId, amount, code, message, setIsApplePay }) => {

    const profileUri = baseUri + "/users";
    const stripe = useStripe();

    const [paymentRequest, setPaymentRequest] = useState(null);
    const [token, setToken] = useState('');
    const [stripeId, setStripeId] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        detectNavegator();
        if (stripe) {

            // Obtenemos el token del usuario.
            firebaseInit(firebase);
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    user.getIdToken(true).then(token => {
                        setToken(token);

                        fetch(`${profileUri}/?email=${user.email}`)
                            .then(response => {
                                response.json().then(data => {
                                    setStripeId(data[0].stripeId);
                                })
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    });
                }
            })

            const pr = stripe.paymentRequest({
                country: 'MX',
                currency: 'mxn',
                total: {
                    label: 'Shift',
                    amount: parseFloat(amount) * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });
        }
    }, [stripe]);

    useEffect(() => {
        // alert('chambia')
    }, [code]);

    const detectNavegator = () => {
        var sBrowser, sUsrAg = navigator.userAgent;
        if (sUsrAg.indexOf("Safari") > -1) {
            if (sUsrAg.indexOf("Chrome") > -1 || sUsrAg.indexOf("CriOS") > -1) {
            }
            else {
                setShow(true);
            }
        }
    }

    if (paymentRequest) {
        paymentRequest.on('paymentmethod', async (ev) => {



            // GET SECRET.
            let data = await paymentIntentRequest(token, stripeId, parseFloat(amount) * 100);
            // alert(JSON.stringify(data.client_secret));
            console.log(data);
            let clientSecret = data.client_secret;

            // alert('HOLA');
            // alert(JSON.stringify(ev));
            // console.log(ev);

            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
            );

            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
                // alert('confirmError');
                // alert(JSON.stringify(confirmError));
            }
            else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const res = await stripe.confirmCardPayment(clientSecret);
                    console.log(res);
                } else {
                    const paymentMethodId = ev.paymentMethod.id;
                    measurePurchases()
                    //
                    suscribeApplePay(token, paymentMethodId, suscriptionId, code !== undefined ? code : '')
                        .then(suscription => {
                            // alert(suscription)

                            if (setIsApplePay !== undefined)
                                setIsApplePay(true);

                            message(true);
                        })
                        .catch(error => {
                            console.log(error);
                            // alert(error);
                        })
                }
            }
            // .then(async data => {

            // })
            // .catch(error => {
            //     console.error(error);
            //     // alert(error);
            // })
            // paymentIntentRequest(token, stripeId, parseFloat(amount) * 100)
            //     .then(async data => {
            //         // alert(JSON.stringify(data.client_secret));
            //         clientSecret = data.client_secret;

            //         // alert('HOLA');
            //         // alert(JSON.stringify(ev));
            //         // console.log(ev);

            //         // Confirm the PaymentIntent without handling potential next actions (yet).
            //         const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
            //             clientSecret,
            //             { payment_method: ev.paymentMethod.id },
            //             { handleActions: false }
            //         );

            //         if (confirmError) {
            //             // Report to the browser that the payment failed, prompting it to
            //             // re-show the payment interface, or show an error message and close
            //             // the payment interface.
            //             ev.complete('fail');
            //             // alert('confirmError');
            //             // alert(JSON.stringify(confirmError));
            //         }
            //         else {
            //             // Report to the browser that the confirmation was successful, prompting
            //             // it to close the browser payment method collection interface.
            //             ev.complete('success');
            //             // Check if the PaymentIntent requires any actions and if so let Stripe.js
            //             // handle the flow. If using an API version older than "2019-02-11"
            //             // instead check for: `paymentIntent.status === "requires_source_action"`.
            //             if (paymentIntent.status === "requires_action") {
            //                 // Let Stripe.js handle the rest of the payment flow.
            //                 const res = await stripe.confirmCardPayment(clientSecret);
            //                 console.log(res);
            //             } else {
            //                 const paymentMethodId = ev.paymentMethod.id;
            //                 suscribeApplePay(token, paymentMethodId, suscriptionId, code)
            //                     .then(suscription => {
            //                         // alert(suscription)

            //                         if(setIsApplePay !== undefined)
            //                             setIsApplePay(true);

            //                         message(true);
            //                     })
            //                     .catch(error => {
            //                         console.log(error);
            //                         // alert(error);
            //                     })
            //             }
            //         }
            //     })
            //     .catch(error => {
            //         console.error(error);
            //         // alert(error);
            //     })
        });
        const measurePurchases = () => {
        
            const orderid = 'order-123';
            const orderTotal = 99.00;
            const product = [
                {
                    id:'product-1',
                    name:'suscripcion shift online',
                    price:99.00,
                    quatity:1
                }
            ];
              
            const transaction = {
                transaction_id:orderid,
                value:orderTotal,
                currency:"MXN",
                items:product
            };
            if(window.gtag){
                window.gtag('event', 'purchase', transaction);
            }
            
        }
        return <>
            {
                show &&
                <PaymentRequestButtonElement options={{ paymentRequest }} />
            }
        </>
    }

    return '';
}
export default CheckoutFormApplePay;
